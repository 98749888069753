import React from 'react'
import Breadcrumb from '../../components/breadcrumb'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import IndexPage from '../../components/indexPage'

const AccessibilityPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/accessibilite" type="index">
      <SEO 
        title="Les sites offrant la meilleure accessibilité de l'Observatoire - 2021"
        description="Quels sont les sites offrant la meilleure accessibilité de l'Observatoire de l'impact positif ? Contraste, typographie, balises ARIA, structures de pages…"
        path="/2021/accessibilite"
        year="2021" />

      <Breadcrumb url="/2021/accessibilite" label="Accessibilité" year="2021" />

      <h1 className="sr-only">Accessibilité</h1>
      <IndexPage slug="accessibility" type="index" year="2021" />
    </Layout>
  )
  }

export default AccessibilityPage

